import React, { useCallback, useMemo } from 'react';

import { useAppContext } from '../../context/context';
import { useClassnames } from '../../hooks/use-classnames';
import VacanciesForm from '../VacanciesForm';

import VacanciesItem, { IVacanciesListItem } from './item';

import './index.css';

import { getCorrectForm, wordForms } from '@/components/vacancies-list/utils';
import { IActiveFilter } from '@/components/VacanciesPage/types';

interface IProps {
    data: Array<IVacanciesListItem>,
    activeTags?: Array<number>,
    onClickTag?: (e: React.MouseEvent<HTMLLIElement>) => void,
    searchString?: string,
    hiddenForm?: boolean,
    activeFilters: Array<IActiveFilter>
}

const VacanciesList: React.FC<IProps> = ({ data, activeTags = [], onClickTag = () => null, searchString = '', hiddenForm = false, activeFilters }) => {
    const cn = useClassnames();

    const { setIsRespondFormVisible, isVisibleFeedbackForms } = useAppContext();

    const counter = useMemo(() => {
        return `
            ${activeFilters.length > 0 || searchString ? getCorrectForm(data.length, wordForms.found) : ''}
            ${data.length}
            ${getCorrectForm(data.length, wordForms.vacancies)}
        `;
    }, [activeFilters, searchString, data]);

    const linkPopupHandler = useCallback(() => {
        setIsRespondFormVisible?.(true);
    }, []);

    return (
        <div className={cn('vacancies__wrap')}>
            {data.length > 0 && <p className="vacancies__counter">{counter}</p>}
            <div className={cn('vacancies__list')}>
                {data?.length ? data.map((item) => (
                    <VacanciesItem key={item.id} data={item} activeTags={activeTags} onClickTag={onClickTag} searchString={searchString} tagsVisible={false} />
                )) : (
                    <div className={cn('vacancies__empty-text')}>
                        <p>Попробуйте изменить выбор в фильтрах для достижения другого результата</p>
                        <p>Сейчас вакансий по этому направлению нет. <span className={cn('vacancies__inner-text-link')} onClick={linkPopupHandler}>Напишите нам</span> и при наличии подходящих вакансий мы обязательно с вами свяжемся</p>
                    </div>)}
            </div>

            <VacanciesForm variant="block" />
        </div>
    );
};

export default VacanciesList;
