import classNames from 'classnames';
import React from 'react';

import './index.css';

interface IProps {
    className?: string
}

const Divider: React.FC<IProps> = ({ className = '' }) => {
    return <div className={classNames('divider', className)} />;
};

export default Divider;
