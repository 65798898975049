import { GetServerDataReturn, navigate } from 'gatsby';
import React, { useEffect } from 'react';

import TeamPage from '../components/TeamPage';

import { GLOBAL_QUERY } from '@/constants';
import { TPageProp } from '@/types';
import { ICareer } from '@/types/strapi/career';
import { IGlobalServerRuntimeData } from '@/types/strapi/global';
import { IDirection, IVacancy } from '@/types/strapi/vacancies';

type TVacancy = Pick<IVacancy, 'id' | 'area' | 'city' | 'jobType' | 'tags' | 'title' | 'direction' | 'isSecret'>;
type TProp = TPageProp<IServerRuntimeData>;

const Team: React.FC<TProp> = ({ serverData }) => {
    const isHiddenPage = false;

    useEffect(() => {
        if(isHiddenPage) {
            void navigate('/');
        }
    }, []);

    if(isHiddenPage) {
        return null;
    }

    return (
        <TeamPage data={serverData} />
    );
};

export default Team;

const query = `
  query getTeams($locale: String) {
    ${GLOBAL_QUERY}
    directions(locale: $locale, sort: "sort:asc") {
      description
      header
      id
      sort
    }
    career(locale: $locale) {
      isHidden
      seo {
        metaTitle
        metaDescription
      }
      top_slider {
        header
        header_position
        slider_items {
          url
        }
      }
      bottom_slider {
        header
        header_position
        slider_items {
          url
        }
      }
      bottom_list {
        header
        list_items {
          header
        }
      }
      vacancies {
        header
        linkButtonText
      }
    }
    vacancies(where: {isSecret: false, isArchived: false}, locale: "ru", limit: 3) {
     id
      locale
      area {
        text
        value
        id
      }
      city {
        text
        value
        id
      }
      jobType {
        text
        value
        id
      }
      tags {
        text
        value
        id
      }
      title
      direction {
        header
        id
      }
     }
  }
`;

interface IServerRuntimeData extends IGlobalServerRuntimeData {
    directions: Array<IDirection>,
    vacancies: Array<TVacancy>,
    career: ICareer
}

export async function getServerData(): Promise<GetServerDataReturn<IServerRuntimeData>> {
    try {
        const variables = JSON.stringify({
            locale: process.env.GATSBY_LOCALE_CODE
        });
        const response = await fetch(
            `${process.env.GATSBY_API_URL}/graphql?query=${query}&variables=${variables}`
        );

        const data: { data: IServerRuntimeData } = await response.json();

        if(data.data.career.isHidden) {
            return {
                status: 404
            };
        }

        return {
            props: {
                career     : data.data.career,
                vacancies  : data.data.vacancies,
                directions : data.data.directions,
                navPanel   : data.data.navPanel,
                global     : data.data.global,
                respondForm: data.data.respondForm,
                footer     : data.data.footer
            }
        };
    } catch(error) {
        return {
            status: 500
        };
    }
}
