import React, { useCallback, useRef, useState } from 'react';

import { useClassnames } from '../../hooks/use-classnames';
import PlayButton from '../../images/play-button-vacancy.inline.svg';

import './index.css';

interface IProps {
    className?: string,
    src: string
}

const VideoBlock: React.FC<IProps> = ({ className, src }) => {
    const cn = useClassnames();
    const videoRef = useRef<HTMLVideoElement>(null);
    const [play, setPlay] = useState<boolean>(false);

    const toggleVideo = useCallback(() => {
        if(videoRef.current) {
            if(videoRef.current.paused) {
                void videoRef.current.play().then(() => setPlay(true));

                return;
            }

            videoRef.current.pause();
            setPlay(false);
        }
    }, []);

    return (
        <div className={cn('video-block', className)} onClick={toggleVideo}>
            <PlayButton className={cn('video-block__play-button', { 'video-block__play-button_hidden': play })} />

            <video
                className={cn('video-block__video')}
                ref={videoRef}
                src={src}
                loop={true}
            />
        </div>
    );
};

export default VideoBlock;
