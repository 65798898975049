import React, { useState } from 'react';

import backgroundVideo from '../../../static/hr-video.mp4';
import { ICareer } from '../../types/strapi/career';
import { IGlobalServerRuntimeData } from '../../types/strapi/global';
import { IDirection, IVacancy } from '../../types/strapi/vacancies';
import { mediaUrl } from '../../utils';
import LinkButton from '../button-like/link-button';
import Divider from '../Divider';
import Footer from '../footer';
import Heading from '../heading';
import Layout from '../layout';
import Slider from '../NewsPage/components/Slider';
import PageSection from '../PageSection';
import VacanciesList from '../vacancies-list';
import VideoBlock from '../VideoBlock';

import './index.css';

type TVacancy = Pick<IVacancy, 'id' | 'area' | 'city' | 'jobType' | 'tags' | 'title' | 'direction' | 'isSecret'>;

interface IQueryData extends IGlobalServerRuntimeData {
    directions: Array<Pick<IDirection, 'description' | 'header' | 'id'>>,
    vacancies: Array<TVacancy>,
    career: Pick<ICareer, 'seo' | 'pageId' | 'top_slider' | 'bottom_slider' | 'bottom_list' | 'vacancies'>
}

interface IProps {
    data: IQueryData
}

const TeamPage = ({ data }: IProps) => {
    const { seo } = data.career;

    // const directions = data.directions.map((direction) => ({
    //     id         : direction.id,
    //     header     : direction.header,
    //     description: direction.description
    // }));

    const [sliderImages] = useState<Array<{ url: string}>>([
        { url: 'https://sberautotech-site-bucket.obs.ru-moscow-1.hc.sbercloud.ru/SAT_K_001_f066ce7f79.jpg' },
        { url: 'https://sberautotech-site-bucket.obs.ru-moscow-1.hc.sbercloud.ru/SAT_K_001_f066ce7f79.jpg' },
        { url: 'https://sberautotech-site-bucket.obs.ru-moscow-1.hc.sbercloud.ru/SAT_K_001_f066ce7f79.jpg' },
        { url: 'https://sberautotech-site-bucket.obs.ru-moscow-1.hc.sbercloud.ru/SAT_K_001_f066ce7f79.jpg' },
        { url: 'https://sberautotech-site-bucket.obs.ru-moscow-1.hc.sbercloud.ru/SAT_K_001_f066ce7f79.jpg' },
        { url: 'https://sberautotech-site-bucket.obs.ru-moscow-1.hc.sbercloud.ru/SAT_K_001_f066ce7f79.jpg' },
        { url: 'https://sberautotech-site-bucket.obs.ru-moscow-1.hc.sbercloud.ru/SAT_K_001_f066ce7f79.jpg' }
    ]);

    return (
        <Layout
            seo={{
                ...seo,
                shareImage: seo?.shareImage?.url ? mediaUrl(seo.shareImage.url) : undefined
            }}
            navPanel={data.navPanel}
            global={data.global}
            respondForm={data.respondForm}
        >
            {/* {top_slider && (*/}
            {/*    <div className="career__carousel">*/}
            {/*        <Carousel data={top_slider} />*/}
            {/*    </div>*/}
            {/* )}*/}
            <PageSection>
                <Heading level={2}>
                    Вместе мы&nbsp;создаем универсальную технологию автономного вождения для развития эффективных
                    грузоперевозок
                    и&nbsp;транспортной инфраструктуры
                </Heading>
                <LinkButton
                    className="team-page__top-button"
                    isGatsbyLink={true}
                    size="l"
                    href="/vacancies"
                >
                    Наши вакансии
                </LinkButton>
            </PageSection>

            <PageSection>
                <div className="team-page__facts">
                    <div className="team-page__facts-item">
                        <Heading level={2} className="team-page__facts-item-title"><span>600</span>+</Heading>
                        <p className="team-page__facts-item-text">уникальных специалистов на&nbsp;стыке&nbsp;IT и&nbsp;Automotive</p>
                    </div>
                    <div className="team-page__facts-item">
                        <Heading level={2} className="team-page__facts-item-title"><span>15</span></Heading>
                        <p className="team-page__facts-item-text">команд, открывающих безграничные возможности для развития в&nbsp;совершенно новой отрасли</p>
                    </div>
                    <div className="team-page__facts-item">
                        <Heading level={2} className="team-page__facts-item-title"><span>200</span>+</Heading>
                        <p className="team-page__facts-item-text">беспилотных автомобилей в&nbsp;парке</p>
                    </div>
                    <div className="team-page__facts-item">
                        <Heading level={2} className="team-page__facts-item-title"><span>5</span></Heading>
                        <p className="team-page__facts-item-text">локаций: комфортный офис (м.&nbsp;Технопарк), полигон для проведения беспилотных испытаний (Московская область), центр разработки HW-платформы (г.&nbsp;Санкт-Петербург), современное производство беспилотных транспортных средств (г.&nbsp;Видное) и&nbsp;центр компетенций машинного зрения и&nbsp;нейросетевой оптимизации (г.&nbsp;Нижний-Новгород)</p>
                    </div>
                </div>
            </PageSection>

            <VideoBlock src={backgroundVideo} className="team-page__video" />

            <PageSection>
                <Heading level={3}>
                    <span className="selection-pattern">Работать в&nbsp;AutoTech</span>&nbsp;&mdash; значит
                </Heading>

                <div className="team-page__advantages">
                    <div className="team-page__advantages-item">
                        <div className="team-page__advantages-item-img" />
                        <Heading level={4} className="team-page__advantages-item-heading">Создавать будущее</Heading>
                        <p className="team-page__advantages-item-text">Вы&nbsp;встанете у&nbsp;истоков принципиально
                            нового направления и&nbsp;будете работать над продуктом, который изменит наш мир.
                        </p>
                    </div>
                    <div className="team-page__advantages-item">
                        <div className="team-page__advantages-item-img" />
                        <Heading level={4} className="team-page__advantages-item-heading">
                            Стать частью сильной команды
                        </Heading>
                        <p className="team-page__advantages-item-text">
                            У&nbsp;вас будет возможность не&nbsp;только создавать уникальные проекты в&nbsp;масштабе страны, но&nbsp;и&nbsp;учиться и&nbsp;работать бок о&nbsp;бок с&nbsp;ведущими специалистами в&nbsp;IT и&nbsp;Automotive сфере.
                        </p>
                    </div>
                    <div className="team-page__advantages-item">
                        <div className="team-page__advantages-item-img" />
                        <Heading level={4} className="team-page__advantages-item-heading">
                            Постоянно развиваться
                        </Heading>
                        <p className="team-page__advantages-item-text">
                            Вы&nbsp;сможете сами строить путь и&nbsp;выбирать инструменты: онлайн и&nbsp;офлайн курсы на&nbsp;ведущих образовательных площадках, участие в&nbsp;отраслевых и&nbsp;профессиональных конференциях.
                        </p>
                    </div>
                    <div className="team-page__advantages-item">
                        <div className="team-page__advantages-item-img" />
                        <Heading level={4} className="team-page__advantages-item-heading">
                            Приносить реальную пользу обществу
                        </Heading>
                        <p className="team-page__advantages-item-text">
                            Благодаря вашей работе доставка грузов станет быстрее и&nbsp;эффективнее, а&nbsp;городской транспорт&nbsp;&mdash; безопаснее и&nbsp;комфортнее.
                        </p>
                    </div>
                    <div className="team-page__advantages-item">
                        <div className="team-page__advantages-item-img" />
                        <Heading level={4} className="team-page__advantages-item-heading">
                            Предлагать смелые решения
                        </Heading>
                        <p className="team-page__advantages-item-text">
                            Наши команды открыты для любых инициатив&nbsp;&mdash; вы&nbsp;сможете реализовать свои уникальные и&nbsp;самые смелые идеи.
                        </p>
                    </div>
                    <div className="team-page__advantages-item">
                        <div className="team-page__advantages-item-img" />
                        <Heading level={4} className="team-page__advantages-item-heading">
                            Получать приятные бонусы
                        </Heading>
                        <p className="team-page__advantages-item-text">
                            Мы&nbsp;создаём все условия, чтобы наши сотрудники чувствовали себя максимально комфортно и&nbsp;могли сосредоточиться на&nbsp;решении важных задач.
                        </p>
                    </div>
                </div>

                <LinkButton
                    className="team-page__top-button"
                    isGatsbyLink={true}
                    size="l"
                    href="/vacancies"
                >
                    Смотреть вакансии
                </LinkButton>
            </PageSection>

            <Divider className="team-page__divider" />

            <PageSection>
                <Heading level={3}>
                    Мы&nbsp;развиваем <span className="selection-pattern">инновационный<br />быстрорастущий</span> продукт:
                </Heading>
                <ul className="team-page__ul">
                    <li>Разрабатываем универсальную технологию автономного вождения, совместимую с&nbsp;разными видами транспорта</li>
                    <li>Создаём платформу для управления флотом беспилотных автомобилей</li>
                    <li>Разрабатываем собственные мультимедийные системы для автопроизводителей</li>
                    <li>Работаем над проектами новой городской мобильности</li>
                </ul>
            </PageSection>

            <PageSection>
                <div className="team-page__horizontal">
                    <h1 className="team-page__horizontal-title">2020</h1>
                    <div className="team-page__horizontal-grid">
                        <Heading level={4}>год основания компании</Heading>

                        <div className="team-page__horizontal-right">
                            <div>
                                <Heading level={4}>более 200</Heading>
                                <p>беспилотных автомобилей в парке</p>
                            </div>
                            <div>
                                <Heading level={4}>более 5 млн км</Heading>
                                <p>пробега</p>
                            </div>
                        </div>
                    </div>
                </div>
            </PageSection>

            <PageSection>
                <div className="team-page__map">
                    map
                </div>
            </PageSection>

            <Divider className="team-page__divider" />

            <PageSection>
                <Heading level={2}>
                    Выберите направление, которое <span className="selection-pattern">вам<br />интересно</span>:
                </Heading>
                <div className="team-page__spacer" />
                <Heading level={4}>
                    Сегодня AutoTech&nbsp;&mdash; это 600+ высококлассных специалистов, обладающих самыми разными
                    компетенциями. Мы&nbsp;активно растём и&nbsp;ждём в&nbsp;команду тех, кто, как и&nbsp;мы, стремится
                    изменить индустрию транспорта и&nbsp;сделать комфортнее жизнь миллионов людей.
                </Heading>

                <div className="team-page__vacancies">
                    <div className="team-page__vacancies-item">
                        <div className="team-page__vacancies-item-left">
                            <Heading className="team-page__vacancies-title" level={4}>Разработка</Heading>
                            <LinkButton
                                className="team-page__top-button"
                                isGatsbyLink={true}
                                size="s"
                                href="/vacancies"
                            >
                                Все вакансии
                            </LinkButton>
                        </div>
                        <div className="team-page__vacancies-item-right">
                            <Heading className="team-page__vacancies-item-right-title" level={5}>Команды</Heading>
                            <div className="team-page__vacancies-item-right-list">
                                <a href="#"><span>Self-driving technology</span></a>
                                <a href="#"><span>Backend</span></a>
                                <a href="#"><span>Frontend</span></a>
                                <a href="#"><span>Mobile</span></a>
                                <a href="#"><span>Machine Learning</span></a>
                                <a href="#"><span>Big data</span></a>
                                <a href="#"><span>Embedded</span></a>
                                <a href="#"><span>Hardware</span></a>
                            </div>
                            <Heading className="team-page__vacancies-item-right-title" level={5}>Тэги</Heading>
                            <div className="team-page__vacancies-item-right-list">
                                <a href="#"><span>C</span></a>
                                <a href="#"><span>C++</span></a>
                                <a href="#"><span>FPGA</span></a>
                                <a href="#"><span>Python</span></a>
                                <a href="#"><span>ML/CV</span></a>
                                <a href="#"><span>CUDA</span></a>
                                <a href="#"><span>2D-детекция</span></a>
                                <a href="#"><span>3D-реконструкция</span></a>
                                <a href="#"><span>Spark</span></a>
                                <a href="#"><span>PyArrow</span></a>
                                <a href="#"><span>Dagster</span></a>
                                <a href="#"><span>S3</span></a>
                                <a href="#"><span>Kafka</span></a>
                                <a href="#"><span>React</span></a>
                                <a href="#"><span>Web</span></a>
                                <a href="#"><span>WebGl</span></a>
                                <a href="#"><span>Airflow</span></a>
                                <a href="#"><span>Mobile</span></a>
                                <a href="#"><span>Flutter</span></a>
                                <a href="#"><span>iOS</span></a>
                                <a href="#"><span>Android</span></a>
                                <a href="#"><span>DevOps</span></a>
                                <a href="#"><span>SysOps</span></a>
                                <a href="#"><span>Docker</span></a>
                                <a href="#"><span>CI/CD</span></a>
                                <a href="#"><span>Kubernetes</span></a>
                                <a href="#"><span>SRE</span></a>
                                <a href="#"><span>PlantUML</span></a>
                                <a href="#"><span>Analytics</span></a>
                                <a href="#"><span>Embedded Software</span></a>
                                <a href="#"><span>Data</span></a>
                            </div>
                        </div>
                    </div>

                    <div className="team-page__vacancies-item">
                        <div className="team-page__vacancies-item-left">
                            <Heading className="team-page__vacancies-title" level={4}>
                                Тестирование
                            </Heading>
                            <LinkButton
                                className="team-page__top-button"
                                isGatsbyLink={true}
                                size="s"
                                href="/vacancies"
                            >
                                Все вакансии
                            </LinkButton>
                        </div>
                        <div className="team-page__vacancies-item-right">
                            <Heading className="team-page__vacancies-item-right-title" level={5}>Команды</Heading>
                            <div className="team-page__vacancies-item-right-list">
                                <a href="#"><span>Автоматизированное</span></a>
                                <a href="#"><span>Ручное</span></a>
                                <a href="#"><span>Гибридное</span></a>
                                <a href="#"><span>Hardware</span></a>
                            </div>
                            <Heading className="team-page__vacancies-item-right-title" level={5}>Тэги</Heading>
                            <div className="team-page__vacancies-item-right-list">
                                <a href="#"><span>Auto QA</span></a>
                                <a href="#"><span>Manual QA</span></a>
                                <a href="#"><span>Web</span></a>
                                <a href="#"><span>Mobile</span></a>
                                <a href="#"><span>Hardware</span></a>
                                <a href="#"><span>HIL</span></a>
                                <a href="#"><span>Python</span></a>
                                <a href="#"><span>Android</span></a>
                                <a href="#"><span>iOS</span></a>
                                <a href="#"><span>Flutter</span></a>
                                <a href="#"><span>Управление автомобилем</span></a>
                            </div>
                        </div>
                    </div>

                    <div className="team-page__vacancies-item">
                        <div className="team-page__vacancies-item-left">
                            <Heading className="team-page__vacancies-title" level={4}>Инженерия</Heading>
                            <LinkButton
                                className="team-page__top-button" isGatsbyLink={true} size="s"
                                href="/vacancies"
                            >
                                Все вакансии
                            </LinkButton>
                        </div>
                        <div className="team-page__vacancies-item-right">
                            <Heading className="team-page__vacancies-item-right-title" level={5}>Команды</Heading>
                            <div className="team-page__vacancies-item-right-list">
                                <a href="#"><span>Проектирование</span></a>
                                <a href="#"><span>Расчеты</span></a>
                                <a href="#"><span>Качество</span></a>
                                <a href="#"><span>Производство</span></a>
                            </div>
                            <Heading className="team-page__vacancies-item-right-title" level={5}>Тэги</Heading>
                            <div className="team-page__vacancies-item-right-list">
                                <a href="#"><span>Конструкции/Макеты</span></a>
                                <a href="#"><span>Моделирование</span></a>
                                <a href="#"><span>Расчеты</span></a>
                                <a href="#"><span>Hardware</span></a>
                                <a href="#"><span>Embedded Software</span></a>
                                <a href="#"><span>Электрика</span></a>
                                <a href="#"><span>Электроника</span></a>
                                <a href="#"><span>Механика</span></a>
                                <a href="#"><span>Схемотехника</span></a>
                                <a href="#"><span>Радиомонтаж</span></a>
                                <a href="#"><span>СМК</span></a>
                                <a href="#"><span>Станочное производство</span></a>
                                <a href="#"><span>Сборочное производство</span></a>
                                <a href="#"><span>Системная инженерия</span></a>
                            </div>
                        </div>
                    </div>

                    <div className="team-page__vacancies-item">
                        <div className="team-page__vacancies-item-left">
                            <Heading className="team-page__vacancies-title" level={4}>Автомотив</Heading>
                            <LinkButton
                                className="team-page__top-button" isGatsbyLink={true} size="s"
                                href="/vacancies"
                            >
                                Все вакансии
                            </LinkButton>
                        </div>
                        <div className="team-page__vacancies-item-right">
                            <Heading className="team-page__vacancies-item-right-title" level={5}>Команды</Heading>
                            <div className="team-page__vacancies-item-right-list">
                                <a href="#"><span>Производство</span></a>
                                <a href="#"><span>Обслуживание</span></a>
                                <a href="#"><span>Автоколонна</span></a>
                            </div>
                            <Heading className="team-page__vacancies-item-right-title" level={5}>Тэги</Heading>
                            <div className="team-page__vacancies-item-right-list team-page__vacancies-item-right-list_tags">
                                <a href="#"><span>Станочное производство</span></a>
                                <a href="#"><span>Сборочное производство</span></a>
                                <a href="#"><span>Обслуживание автомобиля</span></a>
                                <a href="#"><span>Локации Управление автопарком</span></a>
                                <a href="#"><span>Управление автомобилем</span></a>
                                <a href="#"><span>СМК</span></a>
                                <a href="#"><span>Электрика</span></a>
                                <a href="#"><span>Электроника</span></a>
                                <a href="#"><span>Механика</span></a>
                                <a href="#"><span>Поддержка систем</span></a>
                                <a href="#"><span>Поддержка пользователей</span></a>
                            </div>
                        </div>
                    </div>

                    <div className="team-page__vacancies-item">
                        <div className="team-page__vacancies-item-left">
                            <Heading className="team-page__vacancies-title" level={4}>Дизайн</Heading>
                            <LinkButton
                                className="team-page__top-button" isGatsbyLink={true} size="s"
                                href="/vacancies"
                            >
                                Все вакансии
                            </LinkButton>
                        </div>
                        <div className="team-page__vacancies-item-right">
                            <Heading className="team-page__vacancies-item-right-title" level={5}>Команды</Heading>
                            <div className="team-page__vacancies-item-right-list">
                                <a href="#"><span>Промышленный</span></a>
                                <a href="#"><span>Продуктовый</span></a>
                                <a href="#"><span>Графический</span></a>
                                <a href="#"><span>Исследования</span></a>
                            </div>
                            <Heading className="team-page__vacancies-item-right-title" level={5}>Тэги</Heading>
                            <div className="team-page__vacancies-item-right-list">
                                <a href="#"><span>UI</span></a>
                                <a href="#"><span>UX</span></a>
                                <a href="#"><span>Конструкции/Макеты</span></a>
                                <a href="#"><span>Моделирование</span></a>
                                <a href="#"><span>Разработка продукта</span></a>
                                <a href="#"><span>Развитие продукта</span></a>
                                <a href="#"><span>AI-дизайн</span></a>
                                <a href="#"><span>Фото/Видео</span></a>
                                <a href="#"><span>Web</span></a>
                                <a href="#"><span>Mobile</span></a>
                                <a href="#"><span>iOS</span></a>
                                <a href="#"><span>Flutter</span></a>
                            </div>
                        </div>
                    </div>

                    <div className="team-page__vacancies-item">
                        <div className="team-page__vacancies-item-left">
                            <Heading className="team-page__vacancies-title" level={4}>Аналитика</Heading>
                            <LinkButton
                                className="team-page__top-button"
                                isGatsbyLink={true}
                                size="s"
                                href="/vacancies"
                            >
                                Все вакансии
                            </LinkButton>
                        </div>
                        <div className="team-page__vacancies-item-right">
                            <Heading className="team-page__vacancies-item-right-title" level={5}>Команды</Heading>
                            <div className="team-page__vacancies-item-right-list">
                                <a href="#"><span>Бизнес</span></a>
                                <a href="#"><span>Системы</span></a>
                                <a href="#"><span>Стратегия</span></a>
                                <a href="#"><span>Data</span></a>
                            </div>
                            <Heading className="team-page__vacancies-item-right-title" level={5}>Тэги</Heading>
                            <div className="team-page__vacancies-item-right-list">
                                <a href="#"><span>Техническая документация</span></a>
                                <a href="#"><span>Python</span></a>
                                <a href="#"><span>SQL</span></a>
                                <a href="#"><span>Analytics</span></a>
                                <a href="#"><span>Системная инженерия</span></a>
                                <a href="#"><span>A/B тесты</span></a>
                                <a href="#"><span>Unit-экономика</span></a>
                                <a href="#"><span>Анализ рынка</span></a>
                                <a href="#"><span>Data</span></a>
                                <a href="#"><span>Финансовое моделирование</span></a>
                            </div>
                        </div>
                    </div>

                    <div className="team-page__vacancies-item">
                        <div className="team-page__vacancies-item-left">
                            <Heading className="team-page__vacancies-title" level={4}>Управление продуктом</Heading>
                            <LinkButton
                                className="team-page__top-button"
                                isGatsbyLink={true}
                                size="s"
                                href="/vacancies"
                            >
                                Все вакансии
                            </LinkButton>
                        </div>
                        <div className="team-page__vacancies-item-right">
                            <Heading className="team-page__vacancies-item-right-title" level={5}>Тэги</Heading>
                            <div className="team-page__vacancies-item-right-list">
                                <a href="#"><span>Разработка продукта</span></a>
                                <a href="#"><span>Развитие продукта</span></a>
                                <a href="#"><span>Целепологание</span></a>
                                <a href="#"><span>Управление результатом</span></a>
                                <a href="#"><span>Analytics</span></a>
                                <a href="#"><span>Локации</span></a>
                                <a href="#"><span>Run</span></a>
                                <a href="#"><span>Change</span></a>
                            </div>
                        </div>
                    </div>

                    <div className="team-page__vacancies-item">
                        <div className="team-page__vacancies-item-left">
                            <Heading className="team-page__vacancies-title" level={4}>Управление проектами</Heading>
                            <LinkButton
                                className="team-page__top-button"
                                isGatsbyLink={true}
                                size="s"
                                href="/vacancies"
                            >
                                Все вакансии
                            </LinkButton>
                        </div>
                        <div className="team-page__vacancies-item-right">
                            <Heading className="team-page__vacancies-item-right-title" level={5}>Тэги</Heading>
                            <div className="team-page__vacancies-item-right-list">
                                <a href="#"><span>Планирование</span></a>
                                <a href="#"><span>Целепологание</span></a>
                                <a href="#"><span>Процессы</span></a>
                                <a href="#"><span>Delivery</span></a>
                                <a href="#"><span>Управление результатом</span></a>
                                <a href="#"><span>Анализ рынка</span></a>
                                <a href="#"><span>A/B тесты</span></a>
                                <a href="#"><span>Unit-экономика</span></a>
                                <a href="#"><span>Analytics</span></a>
                                <a href="#"><span>Отчетность</span></a>
                                <a href="#"><span>Коммуникации</span></a>
                            </div>
                        </div>
                    </div>

                    <div className="team-page__vacancies-item">
                        <div className="team-page__vacancies-item-left">
                            <Heading className="team-page__vacancies-title" level={4}>Развитие бизнеса</Heading>
                            <LinkButton
                                className="team-page__top-button"
                                isGatsbyLink={true}
                                size="s"
                                href="/vacancies"
                            >
                                Все вакансии
                            </LinkButton>
                        </div>
                        <div className="team-page__vacancies-item-right">
                            <Heading className="team-page__vacancies-item-right-title" level={5}>Команды</Heading>
                            <div className="team-page__vacancies-item-right-list">
                                <a href="#"><span>Стратегия</span></a>
                                <a href="#"><span>Партнеры</span></a>
                                <a href="#"><span>Продажи</span></a>
                                <a href="#"><span>Маркетинг</span></a>
                            </div>
                            <Heading className="team-page__vacancies-item-right-title" level={5}>Тэги</Heading>
                            <div className="team-page__vacancies-item-right-list">
                                <a href="#"><span>Бизнес-модели</span></a>
                                <a href="#"><span>Финансовое моделирование</span></a>
                                <a href="#"><span>Unit-экономика</span></a>
                                <a href="#"><span>Analytics</span></a>
                                <a href="#"><span>Анализ рынка</span></a>
                                <a href="#"><span>Digital</span></a>
                                <a href="#"><span>SMM</span></a>
                                <a href="#"><span>Коммуникации</span></a>
                                <a href="#"><span>Мероприятия</span></a>
                                <a href="#"><span>Локации</span></a>
                                <a href="#"><span>Развитие продукта</span></a>
                                <a href="#"><span>Партнеры</span></a>
                                <a href="#"><span>Привлечение клиентов</span></a>
                                <a href="#"><span>Развитие клиентов</span></a>
                            </div>
                        </div>
                    </div>

                    <div className="team-page__vacancies-item">
                        <div className="team-page__vacancies-item-left">
                            <Heading className="team-page__vacancies-title" level={4}>Инфраструктура</Heading>
                            <LinkButton
                                className="team-page__top-button"
                                isGatsbyLink={true}
                                size="s"
                                href="/vacancies"
                            >
                                Все вакансии
                            </LinkButton>
                        </div>
                        <div className="team-page__vacancies-item-right">
                            <Heading className="team-page__vacancies-item-right-title" level={5}>Команды</Heading>
                            <div className="team-page__vacancies-item-right-list">
                                <a href="#"><span>DevOps</span></a>
                                <a href="#"><span>Администрирование</span></a>
                                <a href="#"><span>SRE</span></a>
                                <a href="#"><span>Автоматизация</span></a>
                                <a href="#"><span>Поддержка</span></a>
                                <a href="#"><span>Кибербезопасность</span></a>
                            </div>
                            <Heading className="team-page__vacancies-item-right-title" level={5}>Тэги</Heading>
                            <div className="team-page__vacancies-item-right-list">
                                <a href="#"><span>Windows</span></a>
                                <a href="#"><span>Linux</span></a>
                                <a href="#"><span>MacOS</span></a>
                                <a href="#"><span>DevOps</span></a>
                                <a href="#"><span>SysOps</span></a>
                                <a href="#"><span>Сетевые технологии</span></a>
                                <a href="#"><span>Docker</span></a>
                                <a href="#"><span>CI/CD</span></a>
                                <a href="#"><span>Kubernetes</span></a>
                                <a href="#"><span>SRE</span></a>
                                <a href="#"><span>Python</span></a>
                                <a href="#"><span>Поддержка систем</span></a>
                                <a href="#"><span>Поддержка пользователей</span></a>
                                <a href="#"><span>Безопасность</span></a>
                                <a href="#"><span>Analytics</span></a>
                            </div>
                        </div>
                    </div>

                    <div className="team-page__vacancies-item">
                        <div className="team-page__vacancies-item-left">
                            <Heading className="team-page__vacancies-title" level={4}>Back office</Heading>
                            <LinkButton
                                className="team-page__top-button"
                                isGatsbyLink={true}
                                size="s"
                                href="/vacancies"
                            >
                                Все вакансии
                            </LinkButton>
                        </div>
                        <div className="team-page__vacancies-item-right">
                            <Heading className="team-page__vacancies-item-right-title" level={5}>Команды</Heading>
                            <div className="team-page__vacancies-item-right-list">
                                <a href="#"><span>HR</span></a>
                                <a href="#"><span>Финансы</span></a>
                                <a href="#"><span>Юристы</span></a>
                                <a href="#"><span>Закупки</span></a>
                                <a href="#"><span>Риски</span></a>
                                <a href="#"><span>Безопасность</span></a>
                                <a href="#"><span>Эксплуатация</span></a>
                                <a href="#"><span>Офис</span></a>
                            </div>
                            <Heading className="team-page__vacancies-item-right-title" level={5}>Тэги</Heading>
                            <div className="team-page__vacancies-item-right-list">
                                <a href="#"><span>Финансовое моделирование</span></a>
                                <a href="#"><span>Unit-экономика</span></a>
                                <a href="#"><span>Analytics</span></a>
                                <a href="#"><span>Закупки</span></a>
                                <a href="#"><span>Поставщики</span></a>
                                <a href="#"><span>Снабжение</span></a>
                                <a href="#"><span>Партнеры</span></a>
                                <a href="#"><span>Бюджет</span></a>
                                <a href="#"><span>Отчетность</span></a>
                                <a href="#"><span>Бухгалтерия</span></a>
                                <a href="#"><span>Планирование</span></a>
                                <a href="#"><span>Склад</span></a>
                                <a href="#"><span>Юриспруденция</span></a>
                                <a href="#"><span>Финансы</span></a>
                                <a href="#"><span>HR</span></a>
                                <a href="#"><span>Безопасность</span></a>
                                <a href="#"><span>Офис-менеджмент</span></a>
                                <a href="#"><span>Охрана труда</span></a>
                                <a href="#"><span>Питание</span></a>
                                <a href="#"><span>Ремонт</span></a>
                                <a href="#"><span>Аренда</span></a>
                                <a href="#"><span>Локации</span></a>
                                <a href="#"><span>Поддержка пользователей</span></a>
                                <a href="#"><span>Поддержка систем</span></a>
                                <a href="#"><span>Коммуникации</span></a>
                            </div>
                        </div>
                    </div>

                </div>
            </PageSection>

            <Divider className="team-page__divider" />

            <PageSection>
                <Heading level={2}>
                    <span className="selection-pattern">Мы&nbsp;заботимся о&nbsp;вас</span> с&nbsp;первого рабочего дня <br />и&nbsp;предлагаем вам:
                </Heading>

                <div className="team-page__offers">
                    <div className="team-page__offers-item">
                        <div className="team-page__offers-item-img" />
                        <p className="team-page__offers-item-text">
                            ДМС с момента оформления, а после испытательного срока — стоматологию и возможность подключить льготную программу для родственников
                        </p>
                    </div>
                    <div className="team-page__offers-item">
                        <div className="team-page__offers-item-img" />
                        <p className="team-page__offers-item-text">Работу в&nbsp;аккредитованной ИТ-компании
                            с&nbsp;ее&nbsp;льготами и&nbsp;преимуществами
                        </p>
                    </div>
                    <div className="team-page__offers-item">
                        <div className="team-page__offers-item-img" />
                        <p className="team-page__offers-item-text">Льготная ипотека, программы кредитования
                            и&nbsp;другие продукты банка на&nbsp;выгодных условиях
                        </p>
                    </div>
                    <div className="team-page__offers-item">
                        <div className="team-page__offers-item-img" />
                        <p className="team-page__offers-item-text">Гибкий график работы</p>
                    </div>
                    <div className="team-page__offers-item">
                        <div className="team-page__offers-item-img" />
                        <p className="team-page__offers-item-text">Компенсация питания в&nbsp;собственном кафе,
                            свежесваренный кофе в&nbsp;кофейне с&nbsp;баристой, большое разнообразие снеков
                            и&nbsp;свежих фруктов на&nbsp;кофе-поинтах
                        </p>
                    </div>
                    <div className="team-page__offers-item">
                        <div className="team-page__offers-item-img" />
                        <p className="team-page__offers-item-text">Широкий спектр дисконт-программ, скидок
                            и&nbsp;привилегий от&nbsp;компаний-партнёров (в&nbsp;том числе скидки на&nbsp;спорт
                            и&nbsp;отдых)
                        </p>
                    </div>
                    <div className="team-page__offers-item">
                        <div className="team-page__offers-item-img" />
                        <p className="team-page__offers-item-text">Клубы по&nbsp;интересам (в&nbsp;том числе спортивные
                            и&nbsp;интеллектуальные&nbsp;/ игровые)
                        </p>
                    </div>
                    <div className="team-page__offers-item">
                        <div className="team-page__offers-item-img" />
                        <p className="team-page__offers-item-text">Бесплатная мультимедиа подписка</p>
                    </div>
                    <div className="team-page__offers-item">
                        <div className="team-page__offers-item-img" />
                        <p className="team-page__offers-item-text">Обучение, конференции и&nbsp;коучинг для выступлений</p>
                    </div>
                    <div className="team-page__offers-item">
                        <div className="team-page__offers-item-img" />
                        <p className="team-page__offers-item-text">Реферальная программа</p>
                    </div>
                    <div className="team-page__offers-item">
                        <div className="team-page__offers-item-img" />
                        <p className="team-page__offers-item-text">Активная жизнь в&nbsp;компании</p>
                    </div>
                </div>
            </PageSection>

            <PageSection>
                <div className="team-page__slider">
                    <Slider items={sliderImages} />
                </div>
            </PageSection>

            <Divider className="team-page__divider" />

            <PageSection>
                <Heading level={2}>
                    Открываем возможности для <br /><span className="selection-pattern">непрерывного профессионального и&nbsp;личностного роста</span>
                </Heading>
                <div className="team-page__spacer" />
                <Heading level={4}>
                    Работать над продуктом, который меняет будущее,&nbsp;&mdash; значит постоянно изучать что-то новое. Мы&nbsp;любим эксперименты и&nbsp;стремимся к&nbsp;постоянному развитию. У&nbsp;нас есть все ресурсы, чтобы каждый мог раскрыть свой потенциал.
                </Heading>

                <div className="team-page__possibilities">
                    <div className="team-page__possibilities-item">
                        <div className="team-page__possibilities-item-img" />
                        <p className="team-page__possibilities-item-text">
                            Постоянно участвуем в профильных выставках, конференциях и семинарах
                        </p>
                    </div>
                    <div className="team-page__possibilities-item">
                        <div className="team-page__possibilities-item-img" />
                        <p className="team-page__possibilities-item-text">
                            Организуем регулярные встречи компании и демо-дни, на которых делимся новостями
                        </p>
                    </div>
                    <div className="team-page__possibilities-item">
                        <div className="team-page__possibilities-item-img" />
                        <p className="team-page__possibilities-item-text">
                            Проводим корпоративные мероприятия, а лиды направлений организуют тимбилдинги внутри команд
                        </p>
                    </div>
                </div>
            </PageSection>

            <Divider className="team-page__divider" />

            <PageSection>
                <Heading level={2}><span className="selection-pattern">Как присоединиться</span> к команде</Heading>

                <div className="team-page__instruction">
                    <div className="team-page__instruction-item">
                        <h4 className="team-page__instruction-item-title">1. Откликнитесь на&nbsp;вакансию</h4>
                        <p className="team-page__instruction-item-text">Выберите подходящую вакансию и&nbsp;расскажите о&nbsp;себе.</p>
                        <LinkButton
                            className="team-page__top-button"
                            isGatsbyLink={true}
                            size="s"
                            href="/vacancies"
                        >
                            Вакансии
                        </LinkButton>
                    </div>
                    <div className="team-page__instruction-item">
                        <h4 className="team-page__instruction-item-title">2. Пройдите этапы интервью</h4>
                        <p className="team-page__instruction-item-text">Мы&nbsp;пригласим вас на&nbsp;несколько встреч, где познакомимся поближе.</p>
                    </div>
                    <div className="team-page__instruction-item">
                        <h4 className="team-page__instruction-item-title">3. Получите оффер</h4>
                        <p className="team-page__instruction-item-text">Если случится мэтч, мы&nbsp;вернёмся с&nbsp;деталями и&nbsp;расскажем о&nbsp;процессе
                            оформления в&nbsp;команду.
                        </p>
                    </div>
                    <div className="team-page__instruction-item">
                        <h4 className="team-page__instruction-item-title">4. Приходите на&nbsp;работу</h4>
                        <p className="team-page__instruction-item-text">Мы&nbsp;познакомим вас с&nbsp;коллективом и&nbsp;приложим все усилия, чтобы период адаптации
                            прошёл максимально комфортно.
                        </p>
                    </div>
                </div>
            </PageSection>

            <Divider className="team-page__divider" />

            <PageSection>
                <Heading level={2}>Горящие вакансии </Heading>
                <VacanciesList data={data.vacancies} hiddenForm={true} />
            </PageSection>

            <Divider className="team-page__divider" />

            <PageSection>
                <div className="team-page__banner">
                    <Heading level={2} className="team-page__banner-title">
                        Мы создаём будущее каждый день. <br />
                        Присоединяйтесь
                    </Heading>
                    <LinkButton
                        className="team-page__banner-button"
                        isGatsbyLink={true}
                        size="l"
                        href="/vacancies"
                    >
                        Смотреть вакансии
                    </LinkButton>
                </div>
            </PageSection>

            {/* {vacancies && (*/}
            {/*    <PageSection>*/}
            {/*        <TeamVacancies*/}
            {/*            header={vacancies.header}*/}
            {/*            linkButtonText={vacancies.linkButtonText}*/}
            {/*            directions={directions}*/}
            {/*        />*/}
            {/*    </PageSection>*/}
            {/* )}*/}
            {/* {bottom_slider && <Carousel data={bottom_slider} />}*/}
            {/* {bottom_list && <AdvantagesList data={bottom_list} />}*/}
            <Footer data={data.footer} />
        </Layout>
    );
};

export default TeamPage;

