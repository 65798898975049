import { Link } from 'gatsby';
import React, { useMemo } from 'react';

import { useClassnames } from '@/hooks/use-classnames';
import Arrow from '@/images/arrow-white-bg.inline.svg';
import Fire from '@/images/fire.inline.svg';
import { formatDateTextShort, toUnescapedHTML } from '@/utils';

import './index.css';

import { IVacancy, TVacanciesHot } from '@/types/strapi/vacancies';

interface IVacancies extends Pick<IVacancy, 'id' | 'title' | 'created_at' | 'tags' | 'city' | 'about' | 'jobType'>{
    isHot?: boolean
}

interface IProps {
    data: IVacancies | TVacanciesHot,
    activeTags: Array<number>,
    onClickTag: (e: React.MouseEvent<HTMLLIElement>) => void,
    tagsVisible: boolean
}


const VacanciesListItem: React.FC<IProps> = ({ data, tagsVisible = false }) => {
    const { id, title, city, direction, tags, jobType } = data;
    const cn = useClassnames();

    const date = useMemo(() => {
        return formatDateTextShort(data.created_at);
    }, [data]);

    return (
        <Link
            className={cn('vacancies__list-item', { 'vacancies__list-item_with-tags': tagsVisible })}
            to={`/vacancies/${id}`}
        >
            <div className="vacancies__list-item__header">
                {tags && tags.length > 0 && (
                    <div className={cn('vacancies__list-top-tags-block')}>
                        {tags.map((tag) => {
                            return (
                                <span className={cn('vacancies__top-tags')} key={tag.id}>
                                    {tag.text}
                                </span>
                            );
                        })}
                    </div>
                )}

                {data.isHot && (
                    <div className={cn('vacancies__fire')}>
                        <Fire />
                    </div>
                )}
            </div>

            <div className={cn('vacancies__list-item-block')} id="block">
                <div className="vacancies__title">
                    {toUnescapedHTML(title)}
                </div>
            </div>
            <div className="vacancies__info">
                <div className="vacancies__info-city">{city?.text}</div>
                {jobType && <div className="vacancies__info-type">{jobType?.text}</div>}
            </div>
            <Arrow className="vacancies__list-item-arrow" />
        </Link>
    );
};

export default VacanciesListItem;
