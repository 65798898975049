export const wordForms = {
    vacancies: ['вакансия', 'вакансии', 'вакансий'],
    found    : ['Найдена', 'Найдено', 'Найдено']
};

export const getCorrectForm = (count: number, wordForms: Array<string>) => {
    const lastDigit = count % 10;
    const lastTwoDigits = count % 100;

    if(lastDigit === 1 && lastTwoDigits !== 11) {
        return wordForms[0];
    } else if(lastDigit >= 2 && lastDigit <= 4 && (lastTwoDigits < 12 || lastTwoDigits > 14)) {
        return wordForms[1];
    }

    return wordForms[2];
};
