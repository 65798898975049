import { Link as GatsbyLink } from 'gatsby';
import React from 'react';

import { useClassnames } from '../../../hooks/use-classnames';
import Loader from '../../loader/loaderComponent';

import './index.css';

type TProps = React.AnchorHTMLAttributes<HTMLAnchorElement> & {
    size?: 'l' | 's',
    variant?: 'primary' | 'secondary' | 'transparent',
    href?: string,
    isActive? : boolean,
    isGatsbyLink?: boolean,
    isBlock?: boolean,
    isLoading?: boolean
};

const LinkButton = (props: TProps) => {
    const cn = useClassnames();
    const { className, children, href, isActive, isGatsbyLink, isBlock, isLoading, size = 'l', variant = 'primary', ...otherProps } = props;

    const classNames = cn(
        'core-link-button',
        className,
        `core-link-button_size-${size}`,
        `core-button_variant-${variant}`,
        {
            'core-link-button_active'     : isActive,
            'core-link-button_block'      : isBlock,
            'core-link-button_text-hidden': isLoading
        }
    );

    return isGatsbyLink ? (
        <GatsbyLink
            className={classNames}
            to={href}
            {...otherProps}
        >
            {children}
            {isLoading && <Loader className={cn('core-link-button__loader')} /> }
        </GatsbyLink>
    ) : (
        <a
            className={classNames}
            href={href}
            {...otherProps}
        >
            {children}
            {isLoading && <Loader className={cn('core-link-button__loader')} /> }
        </a>
    );
};

export default LinkButton;
